import {
  BaseComponent,
  CheckInput,
  ContentGrid,
  type IComponent,
  StyleGrid,
  Text,
  getCounterTheme
} from '@fjordkraft/fjordkraft.component.library'
import classNames from 'classnames'
import { MSCheckInputTemplate, h3TextPrefab, h4TextPrefab, paragraphTextPrefab } from '../../../Prefabs'
import { MSRichText } from '../../../components'
import type { ConsentState } from '../../../models'
import { DetailCheckboxItemTemplate, type IDetailCheckboxItemTemplate } from './DetailCheckboxItemTemplate'
import './DetailCheckboxItem.scss'
import { v4 as uuid4 } from 'uuid'
import { RadioList } from '../../../components/Radio/RadioList'

export interface IDetailCheckboxListItem {
  name?: string
  description?: string
}

export interface IDetailCheckboxListData {
  title?: string
  items?: IDetailCheckboxListItem[]
}

export interface IDetailCheckboxItemData extends Omit<IComponent, 'active'> {
  title?: string
  description?: string
  listData?: IDetailCheckboxListData
  template?: IDetailCheckboxItemTemplate
  location?: 'top' | 'bottom' | 'single'
  type?: 'checkbox' | 'radio'
  state: ConsentState
  checkboxes?: IRadioButton[]
}

export interface IRadioButton {
  text: string
  value: string
}

interface IDetailCheckboxItem extends IDetailCheckboxItemData {
  onChange: (value: any, state: boolean) => void
}

export const DetailCheckboxItem = (props: IDetailCheckboxItem) => {
  // ************************************
  // Properties
  // ************************************

  const {
    id,
    theme = 'Light',
    brand,
    location,
    template = DetailCheckboxItemTemplate(theme, location),
    listData,
    title,
    description,
    disabled,
    state,
    checkboxes,
    onChange,
    type
  } = props
  const classPrefix = 'detail-checkbox-item'

  // ************************************
  // Render Functionality
  // ************************************

  const _renderListItems = (items: IDetailCheckboxListItem[]) => {
    return items.map((item: IDetailCheckboxListItem, index: number) => {
      const key = `${item.name}_${index}`
      return (
        <ContentGrid key={key} direction='column' alignment='top-left' tagType='section' boxSizing='border-box'>
          <Text {...h4TextPrefab()} theme={getCounterTheme(theme)}>
            {item.name}
          </Text>
          <Text {...paragraphTextPrefab()} theme={getCounterTheme(theme)} faded>
            {item.description}
          </Text>
        </ContentGrid>
      )
    })
  }

  const _renderListContent = () => {
    if (listData) {
      return (
        <BaseComponent className={`${classPrefix}__list`} template={template.list}>
          {listData.title && (
            <Text {...h3TextPrefab()} size='medium'>
              {listData.title}
            </Text>
          )}
          {listData.items && (
            <StyleGrid
              className={`${classPrefix}__list__view`}
              direction='column'
              alignment='top-left'
              boxSizing='border-box'
            >
              <ContentGrid
                className={`${classPrefix}__list__view__container`}
                tagType='section'
                alignment='top-left'
                direction='column'
                boxSizing='border-box'
                gap={3}
                key={'contentGridListItems'}
              >
                {_renderListItems(listData.items)}
              </ContentGrid>
            </StyleGrid>
          )}
        </BaseComponent>
      )
    }
  }

  // ************************************
  // Render
  // ************************************

  const _renderLowerRadiobuttons = () => {
    if (type !== 'radio' || !checkboxes) return <></>

    return (
      <ContentGrid
        className={`${classPrefix}__checkbox`}
        direction='column'
        alignment='top-right'
        boxSizing='border-box'
        tagType='section'
      >
        <RadioList
          name={id ?? uuid4()}
          items={checkboxes}
          selectedValue={state}
          onChange={value => {
            onChange(id, value === 'ACCEPTED')
          }}
        />
      </ContentGrid>
    )
  }

  const _renderUpperCheckbox = () => {
    if (type !== 'checkbox') return <></>

    return (
      <ContentGrid
        className={`${classPrefix}__top__right`}
        direction='column'
        alignment='top-right'
        boxSizing='border-box'
        tagType='section'
      >
        <CheckInput
          template={MSCheckInputTemplate(theme, 'checkbox')}
          disabled={disabled}
          active={state === 'ACCEPTED'}
          value={id}
          type='checkbox'
          onChange={(value, state) => {
            onChange(value, state ?? false)
          }}
        />
      </ContentGrid>
    )
  }

  return (
    <BaseComponent
      className={classNames(`${classPrefix}`, {
        [`${classPrefix}--disabled`]: disabled
      })}
      template={template}
      disabled={disabled}
    >
      <StyleGrid
        className={`${classPrefix}__top`}
        direction='row'
        alignment='top-left'
        boxSizing='border-box'
        wrap={false}
      >
        <ContentGrid
          className={`${classPrefix}__top__left`}
          direction='column'
          alignment='top-left'
          tagType='section'
          boxSizing='border-box'
          wrap={false}
          gap={2}
          spaceBetween
        >
          {title && (
            <Text {...h3TextPrefab()} className={`${classPrefix}__top__left__title`} size='medium'>
              {title}
            </Text>
          )}
          {description && (
            <MSRichText
              {...h3TextPrefab()}
              className={`${classPrefix}__top__left__description`}
              brand={brand}
              theme={getCounterTheme(theme)}
              text={description}
              alignment='lawful'
            />
          )}
        </ContentGrid>
        {_renderUpperCheckbox()}
      </StyleGrid>
      {_renderLowerRadiobuttons()}
      {_renderListContent()}
    </BaseComponent>
  )
}
