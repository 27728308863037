import type { CallState } from '@fjordkraft/fjordkraft.component.library'
import { get } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { CallStateToast } from '../../../components/Toastify/Toastify'
import {
  ConsentContext,
  useApplicationCoreDataContext,
  useApplicationDefaultContext,
  useApplicationServicehandlerContext
} from '../../../contexts'
import { useApplicationGuestsAndHostsContext } from '../../../contexts/variations/ApplicationGuestsAndHostsContext'
import { Constants } from '../../../data'
import {
  ConsentStateEnum,
  type IConsent,
  MarketingConsentType,
  newConsentsAnsweredStates,
  newConsentsUnansweredStates
} from '../../../models'
import type { Partner } from '../../../models/interfaces/sanity/MarketingConsent'
import { getMarketingConsentText, getText } from '../../../services'

export const ApplicationConsentWrapper = (props: any) => {
  // ************************************
  // Properties
  // ************************************

  const { GET, GETTYPED, PUT } = useApplicationServicehandlerContext()
  const { defaultProps } = useApplicationDefaultContext()
  const { userData } = useApplicationCoreDataContext()
  const { isGuest } = useApplicationGuestsAndHostsContext()

  const { installation } = defaultProps?.user ?? {}
  const { agreementsWithoutMarketing } = get(defaultProps, 'epiChildren.HeadlessHomePageType.data', {})

  const [customerHasAgreementWithoutMarketing, setCustomerHasAgreementWithoutMarketing] = useState<boolean>(true)

  useEffect(() => {
    if (installation?.meterId && !!GET) {
      checkForAgreementWithoutMarketing()
    } else {
      setCustomerHasAgreementWithoutMarketing(true)
    }
  }, [GET, installation, agreementsWithoutMarketing])

  const checkForAgreementWithoutMarketing = async () => {
    const agreement = await GET(`Agreements/${installation?.meterId}`)
    const agreementId = agreement?.data?.agreementId

    if (agreement !== undefined && agreement !== null) {
      setCustomerHasAgreementWithoutMarketing((agreementsWithoutMarketing ?? []).includes(agreementId?.toString()))
    } else {
      setCustomerHasAgreementWithoutMarketing(true)
    }
  }

  // ************************************
  // Handling
  // ************************************

  const PUT_CONSENTS = async (consents: IConsent[], translations: any) => {
    if (consents?.length && !isGuest) {
      const updatedConsents = await Promise.all(
        consents.map(async (consent: IConsent) => {
          return await PUT('Customers/v2/marketingConsent', consent)
        })
      )

      const hasFailedCall = updatedConsents.some(resp => resp.callState !== 'success')
      const callState = hasFailedCall ? 'error' : ('success' as CallState)

      CallStateToast({
        text: getText(`consent_toast_${callState}`, translations),
        callState: callState
      })

      await updateConsents()
    }
  }

  const newConsentsAndReservationTypes = [
    MarketingConsentType.PersonalizedMarketing,
    MarketingConsentType.GeneralMarketing,
    MarketingConsentType.DisableMarketing
  ]

  const _shouldUseNewConsents = (marketingConsentData: IConsent[]) =>
    marketingConsentData.some(
      consent =>
        newConsentsAndReservationTypes.includes(consent.consentName) &&
        [...newConsentsAnsweredStates, ...newConsentsUnansweredStates].includes(consent.value as ConsentStateEnum)
    )

  const shouldShowConsentModalForCustomer = (marketingConsentsData: IConsent[]) => {
    const consentPopUpClosed = sessionStorage.getItem(Constants.keys.consentPopupClosed)
    const hasPreviouslyClosedPopup = consentPopUpClosed === 'true'
    if (customerHasAgreementWithoutMarketing || hasPreviouslyClosedPopup) return false
    return !_hasAnsweredNewConsents(marketingConsentsData)
  }

  const pageHasPopup = (pathname: string) => {
    const pagesWithNoConsentPopup = [import.meta.env.REACT_APP_CONSENT_PAGE, import.meta.env.REACT_APP_RESERVED_PAGE]
    return !pagesWithNoConsentPopup.includes(pathname)
  }

  const getPartners = async (marketingConsents: IConsent[] | null) => {
    const sanityConsentResponse = await getMarketingConsentText()
    const sanityPartners = sanityConsentResponse.reduce((acc: { [key: string]: Partner[] }, curr) => {
      if (curr?.partners) acc[curr.consentId] = curr.partners
      return acc
    }, {})

    return (
      marketingConsents?.map((consent: IConsent) => {
        if (Object.keys(sanityPartners).includes(consent.consentName)) {
          return { ...consent, partners: sanityPartners[consent.consentName] }
        }
        return consent
      }) ?? []
    )
  }

  const _hasAnsweredNewConsents = (marketingConsents: IConsent[]) => {
    return !marketingConsents?.some(
      (consent: IConsent) =>
        newConsentsAndReservationTypes.includes(consent.consentName) &&
        newConsentsUnansweredStates.includes(consent.value as ConsentStateEnum)
    )
  }

  const setConsentValues = async (marketingConsents: IConsent[]) => {
    const consentsWithPartners = await getPartners(marketingConsents)
    setConsents(consentsWithPartners)
    setHasAnsweredNewConsent(_hasAnsweredNewConsents(marketingConsents))
    setUseNewConsents(_shouldUseNewConsents(consentsWithPartners))

    const unansweredConsents = marketingConsents.filter(
      c =>
        newConsentsUnansweredStates.includes(c.value as ConsentStateEnum) &&
        c.consentName !== MarketingConsentType.DisableMarketing
    )
    setShowConsentModalForCustomer(shouldShowConsentModalForCustomer(unansweredConsents))

    const disableMarketingConsent = findConsent(consentsWithPartners, MarketingConsentType.DisableMarketing)
    setIsReservedAgainstMarketing(disableMarketingConsent?.value === ConsentStateEnum.ACCEPTED)
  }

  const findConsent = (consents: IConsent[] | undefined, consentName: MarketingConsentType) =>
    consents?.find(e => e.consentName === consentName)

  const updateConsents = async (): Promise<IConsent[] | null> => {
    const marketingConsents = await GETTYPED<IConsent[]>('Customers/v2/marketingConsent', true)
    if (marketingConsents?.data?.length) await setConsentValues(marketingConsents.data)

    return marketingConsents.data
  }

  // ************************************
  // Lifecycle
  // ************************************

  const [consents, setConsents] = useState<IConsent[]>()
  const [useNewConsents, setUseNewConsents] = useState<boolean>()
  const [showConsentModalForCustomer, setShowConsentModalForCustomer] = useState<boolean>()
  const [isReservedAgainstMarketing, setIsReservedAgainstMarketing] = useState<boolean>()
  const [hasAnsweredNewConsent, setHasAnsweredNewConsent] = useState<boolean>()

  const _context = useMemo(() => {
    return {
      consents,
      setConsents,
      PUT_CONSENTS,
      updateConsents,
      useNewConsents,
      showConsentModalForCustomer,
      isReservedAgainstMarketing,
      hasAnsweredNewConsent,
      pageHasPopup
    }
  }, [
    consents,
    setConsents,
    useNewConsents,
    showConsentModalForCustomer,
    isReservedAgainstMarketing,
    hasAnsweredNewConsent
  ])

  useEffect(() => {
    if (userData?.customerId) {
      updateConsents()
    }
  }, [userData, customerHasAgreementWithoutMarketing])

  // ************************************
  // Render
  // ************************************

  return <ConsentContext.Provider value={_context}>{props.children}</ConsentContext.Provider>
}
