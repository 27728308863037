import { IconType, Text, getIconSource } from '@fjordkraft/fjordkraft.component.library'
import './RadioList.scss'
import { ReactSVG } from 'react-svg'
import { paragraphTextPrefab } from '../../Prefabs'

type RadioItem = {
  text: string
  value: string
}

type RadioListProps = {
  key?: string
  items: RadioItem[]
  selectedValue: string
  onChange: (value: string) => void
  name: string
}

export const RadioList = ({ key, name, items, selectedValue, onChange }: RadioListProps) => {
  return (
    <div key={key} className='radio-list'>
      {items.map(item => {
        const isSelected = selectedValue === item.value
        return (
          <label key={item.value} className='radio-label'>
            <div className={`radio ${isSelected ? 'checked' : ''}`}>
              <input
                type='radio'
                name={name}
                value={item.value}
                checked={isSelected}
                onChange={() => onChange(item.value)}
                className='radio-input'
              />
              <ReactSVG className='icon' wrapper={'span'} src={getIconSource(IconType.CheckmarkThick)} />
            </div>
            <Text {...paragraphTextPrefab()}>{item.text}</Text>
          </label>
        )
      })}
    </div>
  )
}
