import { ContentGrid, type IAction, StyleGrid, type Theme } from '@fjordkraft/fjordkraft.component.library'
import { type ButtonTypes, MS_ButtonTemplate } from '../../../Prefabs'
import { ActionButton } from '../../../components'

export interface INavigation {
  navigationContent: any
  secondaryAction?: IAction
  secondaryActionStyle: ButtonTypes
  primaryAction?: IAction
  primaryActionStyle: ButtonTypes
  primaryActionDisabled?: boolean
}
export const ContentCoreNav = ({
  classPrefix,
  brand,
  desktopView,
  theme,
  navigation
}: {
  classPrefix: string
  brand?: string
  desktopView: boolean
  theme: Theme
  navigation: INavigation
}) => {
  const {
    navigationContent,
    secondaryAction,
    secondaryActionStyle,
    primaryAction,
    primaryActionStyle,
    primaryActionDisabled
  } = navigation
  return (
    <StyleGrid
      className={`${classPrefix}__card__navigation-wrapper`}
      direction='column'
      alignment='top-left'
      boxSizing='border-box'
      gap={2}
    >
      {navigationContent}
      <ContentGrid
        className={`${classPrefix}__card__navigation`}
        tagType='nav'
        direction='row'
        alignment='center'
        wrap={false}
        gap={4}
      >
        {secondaryAction && (
          <ActionButton
            brand={brand}
            template={MS_ButtonTemplate(theme, secondaryActionStyle)}
            padding={desktopView ? 'default' : 'medium'}
            action={secondaryAction}
          />
        )}
        {primaryAction && (
          <ActionButton
            brand={brand}
            template={MS_ButtonTemplate(theme, primaryActionStyle)}
            padding={desktopView ? 'default' : 'medium'}
            action={primaryAction}
            disabled={primaryActionDisabled}
          />
        )}
      </ContentGrid>
    </StyleGrid>
  )
}
