export type locale = 'nb' | 'en'

export enum ConsentStateEnum {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  UNANSWERED = 'UNANSWERED',
  PENDING = 'PENDING'
}

export const newConsentsUnansweredStates = [ConsentStateEnum.PENDING, ConsentStateEnum.UNANSWERED]
export const newConsentsAnsweredStates = [ConsentStateEnum.ACCEPTED, ConsentStateEnum.DECLINED]

export type ConsentState = keyof typeof ConsentStateEnum

export enum MarketingConsentType {
  PersonalizedMarketing = 'PersonalizedMarketing',
  GeneralMarketing = 'GeneralMarketing',
  DisableMarketing = 'DisableMarketing',
  Legacy1 = 'Legacy1'
}

export type EpiPageType =
  | 'HeadlessAboutEnergyAgreementPageType'
  | 'HeadlessActiveEnergyAgreementPageType'
  | 'HeadlessConsumptionCostPageType'
  | 'HeadlessConsumptionUsePageType'
  | 'HeadlessMovePageType'
  | 'HeadlessInvoicePageType'
  | 'HeadlessInvoiceEditDueDatePageType'

export enum Brand {
  'brand-fjordkraft-chart' = 0,
  'brand-fjordkraft' = 1,
  'brand-steddi' = 2,
  'brand-strømforsikring' = 3,
  'brand-trondelagkraft' = 4,
  'brand-trumf' = 5,
  'brand-vannkraft' = 6,
  'brand-nges' = 7
}

export type StatusType = 'positive' | 'neutral' | 'negative'
